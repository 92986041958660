.name-input {
  text-align: center;
  max-width: 350px;
  width: 100%;
  margin: 0 auto;

  span {
    display: block;
    margin: 20px 0 20px 0;
  }

  input {
    padding: 5px 0;
    font-size: 24px;
    width: 100%;
    text-align: center;
  }
}

.cf {
  padding: 5px;
  background-color: white;
  font-family: Gill Sans, Arial, sans-serif;
  margin: 0;

  .dialog-titlebar {
    padding: 1em;
  }

  .blue-background {
    background-color: rgb(63, 80, 141);
    color: white;
    font-weight: bold;
  }

  .yellow-background {
    background-color: rgb(255, 208, 0);
    color: rgb(52, 55, 65);
    font-weight: bold;
  }

  .teal-background {
    background-color: rgb(120, 225, 225);
    color: rgb(52, 55, 65);
    font-weight: bold;
  }
}

.container { display: grid;}

.inner-row-space{
  margin-bottom: 0.25rem;
}

/*override to match colors for RTCP in Program Participation*/
.lmig-FloatingHeader--bg-full {
  --lmig-FloatingHeader-background-color: rgb(120, 225,225);
}

/*override to match colors for RTCP in Program Participation*/
.lmig-FloatingHeader-brand {
  background-color: rgb(120, 225,225);
}
