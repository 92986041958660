
.center-contents {
  display: flex;
  align-items: center;
  height: 100%;
}

.center-dropdown {
  align-items: center;
}

.right-dropdown {
  justify-content: flex-end;
}

.lm-round {
  border-radius:24px;
}
